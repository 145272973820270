body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-logo {
  background-image: url('./assets/img/logo3.png');
  width: 5rem;
  height: 2.25rem;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
