.policy {
    color: black;
    margin-left: 35px;
    margin-right: 35px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.policy h1 {
    font-size: 18pt;
    font-weight: bold;
    text-align: center;
}

.policy-section h2 {
    font-size: 14pt;
    font-weight: 500;
}

.policy-section h3 {
    font-weight: 500;
    font-size: 12pt;
    text-decoration: underline;
}

.policy-section-number {
    padding-right: 20px;
}

.policy-section p, .policy-section div {
    font-size: 11pt;
    text-align: justify;
}

.policy-section ul {
    padding-inline-start: 30px;
}
.policy-section ul li {
    padding-bottom: 8px;
}
